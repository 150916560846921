import { Grid, Typography, Button } from "@material-ui/core";
import { useStyles } from "./style";

export const Hero = () => {
  const classes = useStyles();

  return (
    <Grid
      spacing={10}
      className={classes.root}
      container
      justify="space-between"
      alignItems="center"
    >
      <Grid item md={5} xs={12}>
        <Typography variant="h4" className={classes.heading}>
          What is ReactShop?
        </Typography>
        <Typography className={classes.detail}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus
          animi quis dignissimos mollitia nemo quod, expedita explicabo facere
          amet quaerat et sint vero sequi, alias fuga repudiandae asperiores?
          Aut, enim.
        </Typography>
        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{ color: "white" }}
          href="#products"
        >
          Start Shopping
        </Button>
      </Grid>

      <Grid item md={5} xs={12}>
        <svg
          className={classes.svg}
          viewBox="0 0 1117 858"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="undraw_add_to_cart_vkjp (1) 1" clipPath="url(#clip0)">
            <g className={classes.bg}>
              <path
                id="Vector"
                opacity="0.1"
                d="M1089.16 582.74C1088.16 583.47 1087.24 584.18 1086.26 584.87C1082.64 587.427 1078.86 589.736 1074.93 591.78C1054.35 602.63 1028.98 607.93 1004.27 611.53C948.85 619.61 892.27 621.18 835.93 622.72C683.7 626.9 531.1 631.07 379.24 621.44C319.24 617.64 257.92 611.28 203.55 591.78C195.757 588.993 188.117 585.92 180.63 582.56C165.48 575.75 150.93 567.46 138.83 557.56C127.46 548.28 118.24 537.62 112.73 525.5C101.24 500.26 107.32 471.91 120.44 447.17C125.889 437.161 132.034 427.548 138.83 418.4C148.96 404.47 160.13 390.94 170.32 377.02C206.58 327.54 230.24 264.94 196.77 214.19C187.15 199.61 173.25 186.81 165.41 171.59C155.41 152.12 156.18 130.5 159.36 109.5C159.773 106.833 160.21 104.193 160.67 101.58C166.1 70.44 176.32 36.89 207.83 17.45C240.56 -2.74999 287.04 -1.72999 327.66 5.45001C390.83 16.73 449.1 40.21 507.99 61.41C566.88 82.61 628.49 101.98 693.21 104.06C750.36 105.9 817.02 92.13 854.95 56.34C891.79 21.58 961.04 18.93 1010.95 39.93C1045.21 54.34 1070.77 80.3 1081.55 109.5C1083.73 115.362 1085.3 121.435 1086.24 127.62C1087.1 133.273 1087.39 139 1087.08 144.71C1086.93 147.53 1086.64 150.32 1086.24 153.08C1077.9 210.7 1017.87 256.15 981.38 307.62C963.55 332.76 950.96 362.96 963.22 390.07C981.92 431.38 1046.95 446.07 1086.22 476.81C1093.61 482.405 1100.12 489.079 1105.52 496.61C1124.57 523.97 1117.54 560.92 1089.16 582.74Z"
                fill="#5CD89B"
              />
              <g id="flowers">
                <path
                  id="Vector_2"
                  d="M257.99 324.86C257.99 324.86 211.99 243.34 142.67 219.86C113.58 210 88.19 191.46 70.83 166.12C62.6114 154.089 55.5149 141.329 49.63 128"
                  stroke="#535461"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_3"
                  d="M6.04001 112.91C13.94 120.54 50.72 128.54 50.72 128.54C50.72 128.54 41.72 92.02 33.8 84.35C29.9929 80.7449 24.9185 78.7852 19.6765 78.8957C14.4345 79.0062 9.44723 81.1779 5.79542 84.9402C2.1436 88.7026 0.121481 93.7524 0.167265 98.9953C0.213048 104.238 2.32305 109.252 6.04001 112.95V112.91Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_4"
                  d="M26.85 179C37.64 181.14 72.85 167.81 72.85 167.81C72.85 167.81 45.4 142.05 34.6 139.91C29.4671 139.006 24.1844 140.149 19.8841 143.093C15.5837 146.038 12.6078 150.55 11.5943 155.662C10.5807 160.774 11.6101 166.08 14.4618 170.443C17.3135 174.805 21.7606 177.877 26.85 179V179Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_5"
                  d="M102.6 250.54C113 246.94 136.6 217.67 136.6 217.67C136.6 217.67 99.92 209.28 89.53 212.88C86.9806 213.672 84.6164 214.968 82.5775 216.691C80.5387 218.415 78.8668 220.53 77.6611 222.912C76.4555 225.293 75.7406 227.893 75.5589 230.556C75.3772 233.22 75.7325 235.892 76.6035 238.416C77.4746 240.939 78.8438 243.262 80.6297 245.246C82.4157 247.23 84.582 248.835 87.0002 249.966C89.4184 251.097 92.0392 251.731 94.7069 251.829C97.3746 251.928 100.035 251.489 102.53 250.54H102.6Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_6"
                  d="M172.1 291.73C182.96 289.93 211.2 265.08 211.2 265.08C211.2 265.08 176.47 250.61 165.61 252.4C160.395 253.261 155.735 256.158 152.655 260.454C149.576 264.751 148.329 270.095 149.19 275.31C150.051 280.525 152.948 285.185 157.244 288.265C161.541 291.344 166.885 292.591 172.1 291.73V291.73Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_7"
                  d="M103.49 139.27C103.49 150.27 83.59 182.2 83.59 182.2C83.59 182.2 63.65 150.29 63.64 139.29C63.5805 136.636 64.052 133.997 65.0266 131.527C66.0012 129.058 67.4593 126.808 69.3154 124.91C71.1715 123.012 73.3881 121.503 75.8351 120.474C78.2821 119.444 80.9102 118.914 83.565 118.914C86.2198 118.914 88.8479 119.444 91.2949 120.474C93.7419 121.503 95.9585 123.012 97.8146 124.91C99.6707 126.808 101.129 129.058 102.103 131.527C103.078 133.997 103.549 136.636 103.49 139.29V139.27Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_8"
                  d="M184.17 188.54C180.88 199.04 152.33 223.54 152.33 223.54C152.33 223.54 142.85 187.09 146.1 176.54C147.847 171.71 151.391 167.74 155.994 165.46C160.596 163.179 165.901 162.763 170.803 164.3C175.704 165.836 179.823 169.205 182.301 173.705C184.778 178.204 185.423 183.487 184.1 188.45L184.17 188.54Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_9"
                  d="M256.01 247.09C254.55 258 230.55 286.97 230.55 286.97C230.55 286.97 215.05 252.69 216.55 241.78C217.327 236.605 220.107 231.943 224.292 228.801C228.476 225.659 233.728 224.288 238.915 224.986C244.101 225.683 248.805 228.392 252.011 232.527C255.217 236.663 256.668 241.894 256.05 247.09H256.01Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_10"
                  opacity="0.25"
                  d="M6.04001 112.91C13.94 120.54 50.72 128.54 50.72 128.54C50.72 128.54 41.72 92.02 33.8 84.35C29.9929 80.7449 24.9185 78.7852 19.6765 78.8957C14.4345 79.0062 9.44723 81.1779 5.79542 84.9402C2.1436 88.7026 0.121481 93.7524 0.167265 98.9953C0.213048 104.238 2.32305 109.252 6.04001 112.95V112.91Z"
                  fill="black"
                />
                <path
                  id="Vector_11"
                  opacity="0.25"
                  d="M26.85 179C37.64 181.14 72.85 167.81 72.85 167.81C72.85 167.81 45.4 142.05 34.6 139.91C29.4671 139.006 24.1844 140.149 19.8841 143.093C15.5837 146.038 12.6078 150.55 11.5943 155.662C10.5807 160.774 11.6101 166.08 14.4618 170.443C17.3135 174.805 21.7606 177.877 26.85 179V179Z"
                  fill="black"
                />
                <path
                  id="Vector_12"
                  opacity="0.25"
                  d="M102.6 250.54C113 246.94 136.6 217.67 136.6 217.67C136.6 217.67 99.92 209.28 89.53 212.88C86.9806 213.672 84.6164 214.968 82.5775 216.691C80.5387 218.415 78.8668 220.53 77.6611 222.912C76.4555 225.293 75.7406 227.893 75.5589 230.556C75.3772 233.22 75.7325 235.892 76.6035 238.416C77.4746 240.939 78.8438 243.262 80.6297 245.246C82.4157 247.23 84.582 248.835 87.0002 249.966C89.4184 251.097 92.0392 251.731 94.7069 251.829C97.3746 251.928 100.035 251.489 102.53 250.54H102.6Z"
                  fill="black"
                />
                <path
                  id="Vector_13"
                  opacity="0.25"
                  d="M172.1 291.73C182.96 289.93 211.2 265.08 211.2 265.08C211.2 265.08 176.47 250.61 165.61 252.4C160.395 253.261 155.735 256.158 152.655 260.454C149.576 264.751 148.329 270.095 149.19 275.31C150.051 280.525 152.948 285.185 157.244 288.265C161.541 291.344 166.885 292.591 172.1 291.73V291.73Z"
                  fill="black"
                />
                <path
                  id="Vector_14"
                  opacity="0.25"
                  d="M103.49 139.27C103.49 150.27 83.59 182.2 83.59 182.2C83.59 182.2 63.65 150.29 63.64 139.29C63.5805 136.636 64.052 133.997 65.0266 131.527C66.0012 129.058 67.4593 126.808 69.3154 124.91C71.1715 123.012 73.3881 121.503 75.8351 120.474C78.2821 119.444 80.9102 118.914 83.565 118.914C86.2198 118.914 88.8479 119.444 91.2949 120.474C93.7419 121.503 95.9585 123.012 97.8146 124.91C99.6707 126.808 101.129 129.058 102.103 131.527C103.078 133.997 103.549 136.636 103.49 139.29V139.27Z"
                  fill="black"
                />
                <path
                  id="Vector_15"
                  opacity="0.25"
                  d="M184.17 188.54C180.88 199.04 152.33 223.54 152.33 223.54C152.33 223.54 142.85 187.09 146.1 176.54C147.847 171.71 151.391 167.74 155.994 165.46C160.596 163.179 165.901 162.763 170.803 164.3C175.704 165.836 179.823 169.205 182.301 173.705C184.778 178.204 185.423 183.487 184.1 188.45L184.17 188.54Z"
                  fill="black"
                />
                <path
                  id="Vector_16"
                  opacity="0.25"
                  d="M256.01 247.09C254.55 258 230.55 286.97 230.55 286.97C230.55 286.97 215.05 252.69 216.55 241.78C217.327 236.605 220.107 231.943 224.292 228.801C228.476 225.659 233.728 224.288 238.915 224.986C244.101 225.683 248.805 228.392 252.011 232.527C255.217 236.663 256.668 241.894 256.05 247.09H256.01Z"
                  fill="black"
                />
                <path
                  id="Vector_17"
                  d="M255.34 323.24C255.34 323.24 246.34 230.06 192.46 180.5C169.87 159.7 154.17 132.5 148.56 102.26C145.927 87.9271 144.615 73.3828 144.64 58.81"
                  stroke="#535461"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_18"
                  d="M110.89 27.38C115.01 37.59 145.42 59.74 145.42 59.74C145.42 59.74 151.97 22.69 147.86 12.48C145.884 7.57349 142.04 3.65286 137.174 1.58058C132.307 -0.491698 126.816 -0.545861 121.91 1.43C117.003 3.40586 113.083 7.2499 111.011 12.1165C108.938 16.983 108.884 22.4735 110.86 27.38H110.89Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_19"
                  d="M103.1 96.23C112.1 102.56 149.67 104.63 149.67 104.63C149.67 104.63 135.1 69.97 126.1 63.64C121.778 60.59 116.422 59.3817 111.209 60.281C105.997 61.1802 101.355 64.1133 98.305 68.435C95.255 72.7567 94.0467 78.113 94.946 83.3256C95.8452 88.5382 98.7783 93.18 103.1 96.23V96.23Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_20"
                  d="M143.41 192.31C154.41 193.24 187.85 176.05 187.85 176.05C187.85 176.05 157.72 153.52 146.75 152.6C144.11 152.319 141.441 152.568 138.9 153.333C136.358 154.097 133.994 155.362 131.948 157.052C129.901 158.743 128.213 160.825 126.982 163.177C125.751 165.529 125.003 168.103 124.78 170.748C124.558 173.393 124.866 176.055 125.686 178.58C126.507 181.104 127.824 183.439 129.559 185.448C131.295 187.456 133.414 189.098 135.792 190.276C138.171 191.455 140.761 192.146 143.41 192.31Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_21"
                  d="M190.25 258.15C200.91 260.91 236.8 249.63 236.8 249.63C236.8 249.63 210.91 222.33 200.26 219.57C195.176 218.338 189.812 219.155 185.325 221.843C180.838 224.531 177.587 228.876 176.275 233.939C174.962 239.002 175.693 244.379 178.31 248.908C180.926 253.438 185.218 256.757 190.26 258.15H190.25Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_22"
                  d="M189.3 90.97C184.85 101.03 153.71 122.14 153.71 122.14C153.71 122.14 148.41 84.9 152.86 74.83C153.891 72.3937 155.396 70.1869 157.288 68.3383C159.18 66.4897 161.422 65.0363 163.882 64.0629C166.341 63.0895 168.97 62.6155 171.615 62.6686C174.26 62.7216 176.868 63.3007 179.286 64.372C181.705 65.4433 183.886 66.9854 185.703 68.9084C187.52 70.8314 188.935 73.0968 189.867 75.5725C190.799 78.0482 191.229 80.6847 191.132 83.3283C191.034 85.9718 190.412 88.5696 189.3 90.97V90.97Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_23"
                  d="M243.1 168.65C235.84 176.92 199.81 187.75 199.81 187.75C199.81 187.75 205.89 150.63 213.16 142.36C214.886 140.394 216.983 138.787 219.33 137.632C221.677 136.476 224.229 135.794 226.84 135.625C229.45 135.455 232.069 135.802 234.546 136.644C237.022 137.487 239.309 138.809 241.275 140.535C243.241 142.261 244.848 144.358 246.003 146.705C247.159 149.052 247.841 151.604 248.01 154.215C248.18 156.825 247.833 159.444 246.991 161.921C246.148 164.397 244.826 166.684 243.1 168.65V168.65Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_24"
                  d="M285.1 251.34C279.34 260.72 245.66 277.48 245.66 277.48C245.66 277.48 245.38 239.86 251.14 230.48C253.906 225.971 258.35 222.746 263.494 221.514C268.638 220.282 274.061 221.144 278.57 223.91C283.079 226.676 286.304 231.12 287.536 236.264C288.768 241.408 287.906 246.831 285.14 251.34H285.1Z"
                  fill="#5CD89B"
                />
              </g>
              <g id="leaves">
                <path
                  id="Vector_25"
                  opacity="0.1"
                  d="M827.21 30.22C827.21 30.22 789.16 27.93 793.65 54.59C793.65 54.59 792.76 59.3 797.03 61.44C797.03 61.44 797.1 59.44 800.93 60.13C802.294 60.3595 803.68 60.4267 805.06 60.33C806.923 60.201 808.693 59.4671 810.1 58.24V58.24C810.1 58.24 820.79 53.83 824.95 36.35C824.95 36.35 828.03 32.53 827.95 31.55L821.53 34.3C821.53 34.3 823.72 38.93 821.99 42.78C821.99 42.78 821.79 34.46 820.55 34.66C820.3 34.66 817.22 36.26 817.22 36.26C817.22 36.26 820.99 44.33 818.14 50.2C818.14 50.2 819.22 40.26 816.04 36.85L811.5 39.54C811.5 39.54 815.91 47.87 812.92 54.67C812.92 54.67 813.69 44.24 810.55 40.18L806.45 43.37C806.45 43.37 810.6 51.54 808.1 57.18C808.1 57.18 807.77 45.03 805.59 44.12C805.59 44.12 802.01 47.28 801.47 48.58C801.47 48.58 804.3 54.58 802.54 57.68C802.54 57.68 801.46 49.6 800.54 49.56C800.54 49.56 796.97 54.92 796.6 58.56C796.773 55.1635 797.832 51.8713 799.67 49.01C797.611 49.3998 795.711 50.3876 794.21 51.85C794.21 51.85 794.76 48.06 800.55 47.72C800.55 47.72 803.5 43.66 804.29 43.41C804.29 43.41 798.53 42.93 795.04 44.48C795.04 44.48 798.11 40.9 805.35 42.48L809.35 39.18C809.35 39.18 801.77 38.18 798.55 39.29C798.55 39.29 802.25 36.13 810.44 38.43L814.84 35.8C814.84 35.8 808.38 34.4 804.53 34.91C804.53 34.91 808.59 32.72 816.13 35.09L819.28 33.68C819.28 33.68 814.54 32.75 813.16 32.6C811.78 32.45 811.7 32.07 811.7 32.07C814.699 31.5709 817.777 31.9172 820.59 33.07C820.59 33.07 827.32 30.64 827.21 30.22Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_26"
                  opacity="0.1"
                  d="M772.98 8.59998C772.98 8.59998 740.98 29.31 760.39 48.12C760.39 48.12 762.46 52.44 767.17 51.64C767.17 51.64 766.05 50 769.54 48.27C770.777 47.647 771.935 46.8782 772.99 45.98C774.415 44.7716 775.408 43.1325 775.82 41.31V41.31C775.82 41.31 781.82 31.41 774.82 14.87C774.82 14.87 775.04 9.96998 774.36 9.24998L770.82 15.25C770.82 15.25 775.33 17.68 776.22 21.8C776.22 21.8 771.13 15.23 770.22 16.12C770.04 16.3 768.48 19.39 768.48 19.39C768.48 19.39 776.31 23.65 777.48 30.06C777.48 30.06 772.48 21.41 767.87 20.56L765.79 25.36C765.79 25.36 774.29 29.46 775.91 36.71C775.91 36.71 770.34 27.85 765.41 26.44L764.01 31.44C764.01 31.44 772.21 35.6 773.52 41.64C773.52 41.64 766.06 32.06 763.76 32.64C763.76 32.64 762.76 37.3 763.08 38.64C763.08 38.64 768.9 41.76 769.34 45.34C769.34 45.34 763.68 39.47 762.95 39.96C762.95 39.96 763.25 46.39 765.12 49.57C763.25 46.7308 762.154 43.4527 761.94 40.06C760.512 41.5939 759.567 43.5133 759.22 45.58C759.22 45.58 757.42 42.2 761.89 38.5C761.89 38.5 761.89 33.5 762.34 32.81C762.34 32.81 757.42 35.81 755.52 39.15C755.52 39.15 755.88 34.45 762.67 31.47L763.97 26.42C763.97 26.42 757.25 30.08 755.34 32.91C755.34 32.91 756.45 28.17 764.4 25.17L766.4 20.44C766.4 20.44 760.4 23.15 757.56 25.84C757.56 25.84 759.56 21.66 767.02 19.11L768.72 16.11C768.72 16.11 764.35 18.17 763.15 18.87C761.95 19.57 761.66 19.31 761.66 19.31C763.779 17.1301 766.462 15.5824 769.41 14.84C769.41 14.84 773.32 8.87998 772.98 8.59998Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_27"
                  opacity="0.1"
                  d="M724.28 32.73C724.28 32.73 705.15 65.73 730.98 73.66C730.98 73.66 734.79 76.57 738.62 73.66C738.62 73.66 736.89 72.72 739.21 69.59C740.016 68.4728 740.687 67.2644 741.21 65.99C741.933 64.2655 742.077 62.3531 741.62 60.54V60.54C741.62 60.54 742.47 49 728.72 37.43C728.72 37.43 726.72 32.97 725.72 32.64L725.29 39.64C725.29 39.64 730.41 39.76 733.08 43.03C733.08 43.03 725.56 39.48 725.18 40.67C725.1 40.91 725.11 44.37 725.11 44.37C725.11 44.37 734.1 44.64 738.1 49.82C738.1 49.82 729.7 44.39 725.23 45.71L725.55 50.93C725.55 50.93 734.98 50.73 739.71 56.46C739.71 56.46 730.71 51.09 725.71 52.07L726.71 57.16C726.71 57.16 735.91 57.16 739.81 61.94C739.81 61.94 728.81 56.78 727.02 58.31C727.02 58.31 728.23 62.93 729.15 64C729.15 64 735.75 64.14 737.77 67.13C737.77 67.13 730.06 64.46 729.63 65.24C729.63 65.24 732.82 70.83 735.93 72.81C732.979 71.1371 730.515 68.7246 728.78 65.81C728.205 67.8237 728.233 69.9619 728.86 71.96C728.86 71.96 725.72 69.77 728.02 64.44C728.02 64.44 725.72 59.98 725.85 59.17C725.85 59.17 722.85 64.1 722.65 67.91C722.65 67.91 720.83 63.56 725.53 57.83L724.4 52.73C724.4 52.73 720.07 59.04 719.65 62.43C719.65 62.43 718.49 57.7 724.22 51.43L723.84 46.32C723.84 46.32 719.7 51.47 718.42 55.14C718.42 55.14 718.28 50.52 723.79 44.85L723.94 41.4C723.94 41.4 720.94 45.21 720.23 46.4C719.52 47.59 719.1 47.42 719.1 47.42C720.001 44.5137 721.69 41.9141 723.98 39.91C723.98 39.91 724.7 32.82 724.28 32.73Z"
                  fill="#5CD89B"
                />
              </g>
            </g>
            <g id="floor flowers">
              <path
                id="Vector_28"
                opacity="0.1"
                d="M182.88 841.86C201.591 841.86 216.76 839.263 216.76 836.06C216.76 832.857 201.591 830.26 182.88 830.26C164.169 830.26 149 832.857 149 836.06C149 839.263 164.169 841.86 182.88 841.86Z"
                fill="#6C63FF"
              />
              <path
                id="Vector_29"
                d="M190.24 771.48C190.24 771.48 195.73 778.67 187.7 789.48C179.67 800.29 173.05 809.48 175.7 816.25C175.7 816.25 187.81 796.1 197.7 795.82C207.59 795.54 201.1 783.6 190.24 771.48Z"
                fill="#5CD89B"
              />
              <path
                id="Vector_30"
                opacity="0.1"
                d="M190.24 771.48C190.72 772.176 191.097 772.937 191.36 773.74C200.98 785.04 206.11 795.59 196.86 795.85C188.25 796.1 177.91 811.5 175.44 815.39C175.522 815.691 175.618 815.988 175.73 816.28C175.73 816.28 187.84 796.13 197.73 795.85C207.62 795.57 201.1 783.6 190.24 771.48Z"
                fill="black"
              />
              <path
                id="Vector_31"
                d="M200.45 780.64C200.45 783.17 200.17 785.22 199.82 785.22C199.47 785.22 199.18 783.22 199.18 780.64C199.18 778.06 199.54 779.3 199.89 779.3C200.24 779.3 200.45 778.11 200.45 780.64Z"
                fill="#FFD037"
              />
              <path
                id="Vector_32"
                d="M203.96 783.66C201.74 784.87 199.8 785.6 199.63 785.29C199.46 784.98 201.13 783.75 203.35 782.54C205.57 781.33 204.69 782.21 204.86 782.54C205.03 782.87 206.18 782.45 203.96 783.66Z"
                fill="#FFD037"
              />
              <path
                id="Vector_33"
                d="M161.22 771.48C161.22 771.48 155.73 778.67 163.76 789.48C171.79 800.29 178.41 809.48 175.76 816.25C175.76 816.25 163.65 796.1 153.76 795.82C143.87 795.54 150.38 783.6 161.22 771.48Z"
                fill="#5CD89B"
              />
              <path
                id="Vector_34"
                opacity="0.1"
                d="M161.22 771.48C160.74 772.176 160.363 772.937 160.1 773.74C150.48 785.04 145.35 795.59 154.6 795.85C163.22 796.1 173.55 811.5 176.02 815.39C175.943 815.693 175.846 815.99 175.73 816.28C175.73 816.28 163.62 796.13 153.73 795.85C143.84 795.57 150.38 783.6 161.22 771.48Z"
                fill="black"
              />
              <path
                id="Vector_35"
                d="M151.01 780.64C151.01 783.17 151.29 785.22 151.64 785.22C151.99 785.22 152.28 783.22 152.28 780.64C152.28 778.06 151.92 779.3 151.57 779.3C151.22 779.3 151.01 778.11 151.01 780.64Z"
                fill="#FFD037"
              />
              <path
                id="Vector_36"
                d="M147.51 783.66C149.73 784.87 151.66 785.6 151.83 785.29C152 784.98 150.33 783.75 148.11 782.54C145.89 781.33 146.77 782.21 146.6 782.54C146.43 782.87 145.29 782.45 147.51 783.66Z"
                fill="#FFD037"
              />
              <path
                id="Vector_37"
                d="M152.76 815.34C152.76 815.34 168.12 814.86 172.76 811.57C177.4 808.28 196.38 804.33 197.53 809.62C198.68 814.91 220.61 835.91 203.27 836.05C185.93 836.19 162.98 833.35 158.36 830.54C153.74 827.73 152.76 815.34 152.76 815.34Z"
                fill="#A8A8A8"
              />
              <path
                id="Vector_38"
                opacity="0.2"
                d="M203.57 834.21C186.23 834.35 163.28 831.51 158.66 828.7C155.14 826.55 153.74 818.86 153.27 815.32H152.76C152.76 815.32 153.76 827.7 158.35 830.52C162.94 833.34 185.92 836.17 203.26 836.03C208.26 836.03 210 834.21 209.9 831.57C209.21 833.19 207.3 834.18 203.57 834.21Z"
                fill="black"
              />
              <path
                id="Vector_39"
                d="M665.7 814.75C665.7 814.75 623.42 812.2 628.41 841.83C628.41 841.83 627.41 847.06 632.16 849.44C632.16 849.44 632.24 847.25 636.5 847.99C638.016 848.245 639.557 848.319 641.09 848.21C643.167 848.073 645.141 847.258 646.71 845.89V845.89C646.71 845.89 658.59 840.99 663.21 821.56C663.21 821.56 666.63 817.32 666.5 816.23L659.36 819.23C659.36 819.23 661.8 824.38 659.88 828.66C659.88 828.66 659.65 819.42 658.28 819.66C658 819.66 654.57 821.44 654.57 821.44C654.57 821.44 658.77 830.44 655.57 836.92C655.57 836.92 656.77 825.92 653.23 822.09L648.23 825.02C648.23 825.02 653.13 834.28 649.81 841.84C649.81 841.84 650.66 830.25 647.18 825.73L642.63 829.28C642.63 829.28 647.23 838.41 644.42 844.68C644.42 844.68 644.06 831.19 641.64 830.17C641.64 830.17 637.64 833.68 637.05 835.17C637.05 835.17 640.2 841.79 638.25 845.29C638.25 845.29 637.05 836.29 636.06 836.29C636.06 836.29 632.06 842.29 631.68 846.29C631.68 846.29 631.86 840.22 635.1 835.68C632.813 836.116 630.703 837.211 629.03 838.83C629.03 838.83 629.65 834.62 636.03 834.25C636.03 834.25 639.31 829.73 640.19 829.46C640.19 829.46 633.79 828.92 629.91 830.64C629.91 830.64 633.32 826.64 641.36 828.47L645.85 824.81C645.85 824.81 637.43 823.65 633.85 824.93C633.85 824.93 637.97 821.41 647.06 823.93L651.96 821.01C651.96 821.01 644.77 819.46 640.49 820.01C640.49 820.01 645.01 817.57 653.39 820.21L656.89 818.64C656.89 818.64 651.62 817.64 650.08 817.45C648.54 817.26 648.46 816.86 648.46 816.86C651.795 816.304 655.219 816.685 658.35 817.96C658.35 817.96 665.83 815.22 665.7 814.75Z"
                fill="#5CD89B"
              />
              <path
                id="Vector_40"
                opacity="0.1"
                d="M636.58 857.08C653.104 857.08 666.5 854.815 666.5 852.02C666.5 849.225 653.104 846.96 636.58 846.96C620.056 846.96 606.66 849.225 606.66 852.02C606.66 854.815 620.056 857.08 636.58 857.08Z"
                fill="#6C63FF"
              />
              <path
                id="Vector_41"
                d="M591.63 816.33C591.63 816.33 567.79 814.89 570.63 831.6C570.487 832.447 570.614 833.318 570.995 834.088C571.376 834.859 571.99 835.489 572.75 835.89C572.75 835.89 572.75 834.65 575.2 835.07C576.052 835.209 576.918 835.252 577.78 835.2C578.95 835.114 580.061 834.655 580.95 833.89V833.89C580.95 833.89 587.65 831.12 590.26 820.17C590.26 820.17 592.18 817.78 592.11 817.17L588.11 818.88C588.11 818.88 589.48 821.79 588.4 824.2C588.4 824.2 588.27 818.99 587.5 819.11C587.34 819.11 585.41 820.11 585.41 820.11C585.41 820.11 587.77 825.11 585.99 828.84C585.99 828.84 586.66 822.61 584.67 820.47L581.84 822.13C581.84 822.13 584.6 827.35 582.73 831.61C582.73 831.61 583.21 825.07 581.24 822.53L578.68 824.53C578.68 824.53 581.27 829.68 579.68 833.21C579.68 833.21 579.47 825.6 578.11 825.03C577.136 825.851 576.266 826.788 575.52 827.82C575.52 827.82 577.3 831.56 576.2 833.53C576.2 833.53 575.52 828.46 574.97 828.44C574.97 828.44 572.73 831.8 572.5 834.1C572.601 831.966 573.264 829.896 574.42 828.1C573.132 828.344 571.943 828.959 571 829.87C571 829.87 571.35 827.49 575 827.29C575 827.29 576.84 824.74 577.34 824.59C577.34 824.59 573.73 824.28 571.54 825.25C571.54 825.25 573.47 823.01 578 824.03L580.53 821.96C580.53 821.96 575.78 821.31 573.77 822.03C573.77 822.03 576.09 820.03 581.22 821.49L583.97 819.85C581.851 819.38 579.679 819.192 577.51 819.29C577.51 819.29 580.06 817.92 584.78 819.4L586.78 818.52C586.78 818.52 583.78 817.93 582.95 817.84C582.12 817.75 582.03 817.51 582.03 817.51C583.912 817.197 585.843 817.412 587.61 818.13C587.61 818.13 591.7 816.6 591.63 816.33Z"
                fill="#5CD89B"
              />
              <path
                id="Vector_42"
                opacity="0.1"
                d="M575.21 840.2C584.527 840.2 592.08 838.924 592.08 837.35C592.08 835.776 584.527 834.5 575.21 834.5C565.893 834.5 558.34 835.776 558.34 837.35C558.34 838.924 565.893 840.2 575.21 840.2Z"
                fill="#6C63FF"
              />
              <path
                id="Vector_43"
                d="M637 780.92C637 780.92 608.23 779.18 611.62 799.35C611.446 800.373 611.6 801.425 612.059 802.355C612.519 803.286 613.262 804.047 614.18 804.53C614.18 804.53 614.18 803.04 617.13 803.53C618.161 803.695 619.208 803.745 620.25 803.68C621.663 803.579 623.006 803.025 624.08 802.1V802.1C624.08 802.1 632.17 798.76 635.31 785.53C635.31 785.53 637.64 782.65 637.55 781.91L632.69 783.98C632.69 783.98 634.35 787.49 633.05 790.4C633.05 790.4 632.89 784.11 631.95 784.26C631.77 784.26 629.43 785.47 629.43 785.47C629.43 785.47 632.29 791.58 630.13 796.01C630.13 796.01 630.95 788.49 628.54 785.91L625.12 787.91C625.12 787.91 628.46 794.21 626.2 799.36C626.2 799.36 626.78 791.47 624.4 788.36L621.3 790.77C621.3 790.77 624.44 796.98 622.53 801.25C622.53 801.25 622.28 792.07 620.63 791.37C620.63 791.37 617.92 793.76 617.51 794.74C617.51 794.74 619.66 799.25 618.32 801.63C618.32 801.63 617.51 795.52 616.84 795.49C616.84 795.49 614.14 799.49 613.84 802.32C613.969 799.752 614.769 797.263 616.16 795.1C614.604 795.396 613.169 796.14 612.03 797.24C612.03 797.24 612.45 794.37 616.83 794.12C616.83 794.12 619.06 791.05 619.66 790.86C619.66 790.86 615.3 790.5 612.66 791.67C612.66 791.67 614.98 788.96 620.46 790.19L623.52 787.7C623.52 787.7 617.78 786.91 615.35 787.78C615.35 787.78 618.15 785.39 624.35 787.13L627.68 785.13C627.68 785.13 622.79 784.13 619.88 784.46C619.88 784.46 622.95 782.8 628.66 784.59L631.04 783.53C631.04 783.53 627.45 782.82 626.4 782.71C625.35 782.6 625.3 782.31 625.3 782.31C627.57 781.932 629.899 782.192 632.03 783.06C632.03 783.06 637.1 781.24 637 780.92Z"
                fill="#5CD89B"
              />
              <path
                id="Vector_44"
                opacity="0.1"
                d="M617.19 809.73C628.44 809.73 637.56 808.19 637.56 806.29C637.56 804.39 628.44 802.85 617.19 802.85C605.94 802.85 596.82 804.39 596.82 806.29C596.82 808.19 605.94 809.73 617.19 809.73Z"
                fill="#6C63FF"
              />
              <path
                id="Vector_45"
                opacity="0.1"
                d="M408.16 851.7C469.756 851.7 519.69 845.199 519.69 837.18C519.69 829.161 469.756 822.66 408.16 822.66C346.564 822.66 296.63 829.161 296.63 837.18C296.63 845.199 346.564 851.7 408.16 851.7Z"
                fill="#6C63FF"
              />
            </g>
            <g className={classes.browser}>
              <g id="browser_2">
                <path
                  id="Vector_46"
                  d="M1088.1 584.67V95.54H136.75V556.61C148.9 566.75 163.51 575.28 178.75 582.29C186.257 585.75 193.923 588.913 201.75 591.78H1076.75L1088.1 584.67Z"
                  fill="url(#paint0_linear)"
                />
                <path
                  id="Vector_47"
                  d="M1086.26 584.87V109.54H138.83V557.64C150.93 567.5 165.48 575.79 180.63 582.64C188.11 585.973 195.75 589.047 203.55 591.86H1074.93L1086.26 584.87Z"
                  fill="white"
                />
                <path
                  id="Vector_48"
                  d="M1086.26 96.93H138.84V122.07H1086.26V96.93Z"
                  fill="#5A5773"
                />
              </g>
              <g id="buttons">
                <path
                  id="Vector_49"
                  d="M157.76 116C161.35 116 164.26 113.09 164.26 109.5C164.26 105.91 161.35 103 157.76 103C154.17 103 151.26 105.91 151.26 109.5C151.26 113.09 154.17 116 157.76 116Z"
                  fill="white"
                />
                <path
                  id="Vector_50"
                  d="M175.26 116C178.85 116 181.76 113.09 181.76 109.5C181.76 105.91 178.85 103 175.26 103C171.67 103 168.76 105.91 168.76 109.5C168.76 113.09 171.67 116 175.26 116Z"
                  fill="white"
                />
                <path
                  id="Vector_51"
                  d="M192.76 116C196.35 116 199.26 113.09 199.26 109.5C199.26 105.91 196.35 103 192.76 103C189.17 103 186.26 105.91 186.26 109.5C186.26 113.09 189.17 116 192.76 116Z"
                  fill="white"
                />
              </g>
              <g id="boxes">
                <path
                  id="Vector_52"
                  d="M537.45 177.32H380.95V275.66H537.45V177.32Z"
                  fill="#5CD89B"
                />
                <path
                  id="Vector_53"
                  opacity="0.2"
                  d="M320.76 177.32H164.26V275.66H320.76V177.32Z"
                  fill="#5A5773"
                />
                <path
                  id="Vector_54"
                  opacity="0.2"
                  d="M754.14 177.32H597.64V275.66H754.14V177.32Z"
                  fill="#5A5773"
                />
                <path
                  id="Vector_55"
                  opacity="0.2"
                  d="M320.76 317.91H164.26V416.25H320.76V317.91Z"
                  fill="#5A5773"
                />
                <path
                  id="Vector_56"
                  opacity="0.2"
                  d="M537.45 317.91H380.95V416.25H537.45V317.91Z"
                  fill="#5A5773"
                />
                <path
                  id="Vector_57"
                  opacity="0.2"
                  d="M754.14 317.91H597.64V416.25H754.14V317.91Z"
                  fill="#5A5773"
                />
                <path
                  id="Vector_58"
                  opacity="0.2"
                  d="M320.76 458.49H164.26V556.83H320.76V458.49Z"
                  fill="#5A5773"
                />
                <path
                  id="Vector_59"
                  opacity="0.2"
                  d="M537.45 458.49H380.95V556.83H537.45V458.49Z"
                  fill="#5A5773"
                />
                <path
                  id="Vector_60"
                  opacity="0.2"
                  d="M754.14 458.49H597.64V556.83H754.14V458.49Z"
                  fill="#5A5773"
                />
              </g>
            </g>
            <g id="woman">
              <g id="woman_2">
                <path
                  id="Vector_61"
                  d="M499.1 263.46L488.3 267.28L484.38 260.78C481.38 255.78 477.22 248.94 474.15 243.97C468.73 235.23 475.82 221.91 475.82 221.91C475.82 221.91 477.1 208.54 481.66 204.42C486.22 200.3 487.49 221.91 487.49 221.91C505.8 230.23 495.99 249.8 495.99 249.8L497.77 257.56L499.1 263.46Z"
                  fill="#EFB7B9"
                />
                <path
                  id="Vector_62"
                  opacity="0.1"
                  d="M499.1 263.46L488.3 267.28L484.38 260.78V260.21C484.38 260.21 490.57 255.96 497.79 257.56L499.1 263.46Z"
                  fill="black"
                />
                <path
                  id="Vector_63"
                  d="M481.66 307.26L487.07 302.68L484.36 261.46C484.36 261.46 498.36 251.88 508.72 266.87C508.72 266.87 508.3 276.03 508.72 278.53C509.14 281.03 505.81 283.53 508.72 286.02C511.63 288.51 508.72 296.85 508.72 296.85C508.72 296.85 512.01 302.68 514.09 304.34C516.17 306 518.26 319.75 514.09 322.66C509.92 325.57 502.1 332.24 502.1 335.15C502.1 338.06 492.1 345.15 492.1 345.15L483.32 333.54L481.66 307.26Z"
                  fill="#FF6484"
                />
                <path
                  id="Vector_64"
                  d="M364.66 557.48C344.48 548.92 348.96 528.01 352.76 517.48C353.519 515.347 354.411 513.262 355.43 511.24L366.75 504.37C366.75 504.37 369.06 510.26 371.53 518.16C377.26 536.54 383.85 565.63 364.66 557.48Z"
                  fill="#EFB7B9"
                />
                <path
                  id="Vector_65"
                  d="M392.4 748.27C391.034 750.932 390.025 753.764 389.4 756.69C388.401 761.398 387.965 766.209 388.1 771.02C388.18 774.35 388.46 776.53 388.46 776.53L365.98 791.99C365.98 791.99 366.11 790.18 366.33 787.52C366.91 780.44 368.12 767.3 369.26 765.6C370.05 764.42 370.6 759.68 370.95 755.28C371.3 750.88 371.44 747.02 371.44 747.02C371.44 747.02 398.49 736.88 392.4 748.27Z"
                  fill="#EFB7B9"
                />
                <path
                  id="Vector_66"
                  d="M445.49 759.36C445.49 759.36 445.49 763.66 445.33 768.66C445.17 773.66 444.87 779.56 444.24 782.31C443.66 784.83 443.62 789.46 443.73 793.73C443.88 798.73 444.24 803.23 444.24 803.23C444.24 803.23 421.29 781.53 423.63 779.81C424.26 779.35 424.88 777.5 425.45 775.04C426.15 772.04 426.78 768.04 427.25 764.64C427.81 760.59 428.16 757.33 428.16 757.33L445.49 759.36Z"
                  fill="#EFB7B9"
                />
                <path
                  id="Vector_67"
                  opacity="0.1"
                  d="M392.4 748.27C391.034 750.932 390.025 753.764 389.4 756.69C383.247 756.529 377.106 756.058 371 755.28C371.34 750.98 371.49 747.02 371.49 747.02C371.49 747.02 398.49 736.88 392.4 748.27Z"
                  fill="black"
                />
                <path
                  id="Vector_68"
                  opacity="0.1"
                  d="M445.49 759.36C445.49 759.36 445.49 763.66 445.33 768.66C438.17 770.49 430.81 766.83 427.25 764.66C427.81 760.61 428.16 757.35 428.16 757.35L445.49 759.36Z"
                  fill="black"
                />
                <path
                  id="Vector_69"
                  d="M492.48 546.66C483.32 566.66 479.57 590.38 479.57 590.38L476.24 610.38C476.24 610.38 477.91 619.12 476.24 622.45C474.57 625.78 471.24 631.2 471.24 631.2C471.24 631.2 473.74 651.6 471.24 653.68C468.74 655.76 471.24 668.25 471.24 668.25L462.91 710.25L451.25 764.37C440.01 773.54 425.05 761.88 425.05 761.88L428.35 726.49C428.35 726.49 433.35 664.49 431.27 661.95C429.19 659.41 432.1 651.54 432.1 651.54C432.1 651.54 435.02 644.04 432.94 641.13C430.86 638.22 433.35 631.97 433.35 631.97C433.35 631.97 432.52 606.57 435.02 600.74C437.52 594.91 435.02 558.85 435.02 558.85L434.02 547.62L433.44 541.4L433.39 540.79C433.39 540.79 433.01 543.28 432.39 547.14C430.55 558.89 426.63 583.24 425.08 588.25C424.026 591.86 422.595 595.35 420.81 598.66C420.81 598.66 422.97 611.15 420.81 614.48C418.65 617.81 420.48 621.56 420.48 621.56C420.48 621.56 422.56 631.97 416.31 636.13C410.06 640.29 416.31 642.38 416.31 642.38C416.31 642.38 411.73 652.38 410.07 656.53C408.41 660.68 410.07 666.53 410.07 666.53L406.74 700.25C406.74 700.25 403.82 714.4 404.24 723.98C404.66 733.56 400.49 755.62 400.49 755.62C400.49 755.62 368.43 755.62 363.49 751.87C358.55 748.12 371.74 711.54 371.74 711.54C371.74 711.54 375.49 647.42 374.65 645.76C373.81 644.1 374.65 631.6 377.57 629.11C380.49 626.62 377.57 621.61 377.57 621.61C377.57 621.61 376.74 611.61 377.57 609.12C378.4 606.63 377.44 598.29 377.44 598.29C377.44 598.29 374.65 577.89 374.24 566.23C373.83 554.57 371.74 528.35 371.74 528.35C371.74 528.35 373.92 512.13 374.25 504.41C374.37 501.57 375.64 498.02 377.25 494.68C379.052 490.909 381.124 487.273 383.45 483.8C383.45 483.8 482.1 454.64 486.24 457.54C487.34 458.31 488.94 463.54 490.52 471.11C494.92 492.26 499.22 531.96 492.48 546.66Z"
                  fill="#444053"
                />
                <path
                  id="Vector_70"
                  d="M431.7 292.19C447.567 292.19 460.43 279.327 460.43 263.46C460.43 247.593 447.567 234.73 431.7 234.73C415.833 234.73 402.97 247.593 402.97 263.46C402.97 279.327 415.833 292.19 431.7 292.19Z"
                  fill="#A1616A"
                />
                <path
                  id="Vector_71"
                  opacity="0.1"
                  d="M490.52 471.13C487.21 472.83 484.99 474 484.99 474C484.99 474 438.36 496.07 425.1 494.82C414.84 493.82 388.65 494.39 377.24 494.67C379.042 490.899 381.114 487.263 383.44 483.79C383.44 483.79 482.1 454.64 486.24 457.54C487.34 458.33 488.94 463.54 490.52 471.13Z"
                  fill="black"
                />
                <path
                  id="Vector_72"
                  d="M411.71 309.34C411.71 309.34 385.1 313.54 378.82 317.66C372.54 321.78 360.1 329.74 360.1 346.81C360.1 363.88 357.19 381.37 355.52 386.36C353.85 391.35 381.75 431.74 381.75 431.74L371.75 492.95C371.75 492.95 411.75 491.7 425.07 492.95C438.39 494.2 485 472.13 485 472.13C485 472.13 512.48 457.56 518.31 457.13C524.14 456.7 506.65 425.07 506.65 425.07C506.65 425.07 479.58 373.87 484.16 365.54C488.74 357.21 492.1 345.14 492.1 345.14L487.1 302.68C487.1 302.68 479.61 300.18 473.36 304.76C467.11 309.34 445.88 306.84 445.88 306.84L411.71 309.34Z"
                  fill="#FF6484"
                />
                <path
                  id="Vector_73"
                  opacity="0.1"
                  d="M366.54 383.45L356.1 386.36L353.21 425.54C353.21 425.54 356.96 435.95 353.21 439.28C349.46 442.61 348.63 513.81 348.63 513.81C348.63 513.81 360.71 522.55 374.86 515.89C374.86 515.89 372.78 507.98 379.03 479.25C385.28 450.52 385.27 435.95 385.27 435.95C385.27 435.95 385.27 400.14 388.19 394.31C391.11 388.48 388.19 373.91 388.19 373.91C388.19 373.91 382.36 366 382.77 363.09C383.18 360.18 366.54 383.45 366.54 383.45Z"
                  fill="black"
                />
                <path
                  id="Vector_74"
                  opacity="0.1"
                  d="M371.53 518.19C365.399 520.254 358.726 520.012 352.76 517.51C353.519 515.376 354.411 513.292 355.43 511.27L366.75 504.4C366.75 504.4 369.1 510.29 371.53 518.19Z"
                  fill="black"
                />
                <path
                  id="Vector_75"
                  d="M365.91 383.45L355.5 386.36L352.59 425.54C352.59 425.54 356.34 435.95 352.59 439.28C348.84 442.61 348.01 513.81 348.01 513.81C348.01 513.81 360.1 522.54 374.24 515.85C374.24 515.85 372.16 507.94 378.4 479.21C384.64 450.48 384.65 435.91 384.65 435.91C384.65 435.91 384.65 400.1 387.56 394.27C390.47 388.44 387.56 373.87 387.56 373.87C387.56 373.87 381.73 365.96 382.15 363.05C382.57 360.14 365.91 383.45 365.91 383.45Z"
                  fill="#FF6484"
                />
                <path
                  id="Vector_76"
                  opacity="0.1"
                  d="M472.5 389.69C472.5 389.69 467.09 436.32 451.26 445.07C451.26 445.07 486.65 435.08 472.5 389.69Z"
                  fill="black"
                />
                <path
                  id="Vector_77"
                  opacity="0.1"
                  d="M392.98 393.86C392.98 393.86 412.54 403.43 407.98 410.09C403.42 416.75 392.98 393.86 392.98 393.86Z"
                  fill="black"
                />
                <path
                  id="Vector_78"
                  opacity="0.1"
                  d="M392.98 413.01C392.98 413.01 405.47 425.92 402.98 430.5C400.49 435.08 392.98 413.01 392.98 413.01Z"
                  fill="black"
                />
                <path
                  id="Vector_79"
                  opacity="0.1"
                  d="M493.73 306.01C493.73 306.01 498.63 314.33 499.1 319.75C499.57 325.17 493.73 306.01 493.73 306.01Z"
                  fill="black"
                />
                <path
                  id="Vector_80"
                  opacity="0.1"
                  d="M493.73 294.77C493.73 294.77 496.19 292.68 502.47 294.77C508.75 296.86 493.73 294.77 493.73 294.77Z"
                  fill="black"
                />
                <path
                  id="Vector_81"
                  opacity="0.1"
                  d="M469.69 538.12C459.374 539.583 448.854 538.236 439.24 534.22L433.99 547.66L433.41 541.44C434.27 536.22 435.94 530.63 439.24 529.22C439.24 529.22 450.8 538.43 469.69 538.12Z"
                  fill="black"
                />
                <path
                  id="Vector_82"
                  opacity="0.1"
                  d="M384.28 555.61C384.28 555.61 409.89 560.92 417.07 559.98L384.28 555.61Z"
                  fill="black"
                />
                <path
                  id="Vector_83"
                  opacity="0.1"
                  d="M386.94 562.32C386.94 562.32 397.87 561.85 403.33 564.04L386.94 562.32Z"
                  fill="black"
                />
                <path
                  id="Vector_84"
                  opacity="0.1"
                  d="M388.19 566.85C393.177 567.909 398.239 568.578 403.33 568.85L388.19 566.85Z"
                  fill="black"
                />
                <path
                  id="Vector_85"
                  opacity="0.1"
                  d="M387.56 624.15C388.03 623.84 409.42 618.84 412.08 622.43L387.56 624.15Z"
                  fill="black"
                />
                <path
                  id="Vector_86"
                  opacity="0.1"
                  d="M387.56 630.71C387.56 630.71 406.14 628.52 406.61 630.71C407.08 632.9 387.56 630.71 387.56 630.71Z"
                  fill="black"
                />
                <path
                  id="Vector_87"
                  opacity="0.1"
                  d="M387.56 636.64C387.56 636.64 407.39 632.54 409.73 633.54C412.07 634.54 387.56 636.64 387.56 636.64Z"
                  fill="black"
                />
                <path
                  id="Vector_88"
                  opacity="0.1"
                  d="M388.5 776.54L366.02 792C366.02 792 366.15 790.19 366.37 787.53C371.37 775.23 377.88 768.74 377.88 768.74C382.95 767.42 386.15 768.64 388.14 771.03C388.22 774.35 388.5 776.54 388.5 776.54Z"
                  fill="black"
                />
                <path
                  id="Vector_89"
                  d="M390.69 790.43C387.513 797.677 385.915 805.518 386 813.43C386.127 819.918 384.481 826.318 381.24 831.94C380.771 832.732 380.243 833.488 379.66 834.2C379.66 834.2 364.04 834.07 358.57 829.54C357.955 829.054 357.454 828.439 357.1 827.74C353.97 821.5 363.19 797.61 363.19 797.61C368.03 779.19 377.86 769.35 377.86 769.35C395.37 764.82 390.69 790.43 390.69 790.43Z"
                  fill="#444053"
                />
                <path
                  id="Vector_90"
                  opacity="0.1"
                  d="M381.24 831.89C380.771 832.682 380.243 833.438 379.66 834.15C379.66 834.15 364.04 834.02 358.57 829.49C361.26 820.59 369.84 793.66 373.86 798.24C377.64 802.54 380.27 823.23 381.24 831.89Z"
                  fill="black"
                />
                <path
                  id="Vector_91"
                  d="M379.1 799.91L378.01 840.25H383.25C383.25 840.25 384.1 804.86 386.44 799.91C388.78 794.96 379.1 799.91 379.1 799.91Z"
                  fill="#444053"
                />
                <path
                  id="Vector_92"
                  opacity="0.1"
                  d="M444.24 803.23C444.24 803.23 421.29 781.53 423.63 779.81C424.26 779.35 424.88 777.5 425.45 775.04C429.86 774.27 437.45 776.11 443.73 793.73C443.88 798.73 444.24 803.23 444.24 803.23Z"
                  fill="black"
                />
                <path
                  id="Vector_93"
                  d="M447.67 827.43C447.494 827.506 447.309 827.559 447.12 827.59C444.42 828.06 434.47 827.79 428.9 827.59C427.879 827.556 426.912 827.128 426.201 826.395C425.49 825.661 425.092 824.681 425.09 823.66C425.09 822.16 425.09 820.33 424.97 818.38C424.71 812.22 423.97 804.89 421.76 803.24C417.85 800.24 414.76 790.06 421.76 777.17C421.76 777.17 435.18 766.92 444.86 797.73C444.86 797.73 445.8 806.52 450.01 809.64C454.22 812.76 449.55 826.54 447.67 827.43Z"
                  fill="#444053"
                />
                <path
                  id="Vector_94"
                  opacity="0.1"
                  d="M447.1 827.59C444.4 828.06 434.45 827.79 428.88 827.59C427.859 827.556 426.892 827.128 426.181 826.395C425.47 825.661 425.072 824.681 425.07 823.66C425.07 822.16 425.07 820.33 424.95 818.38C426.64 810.92 429.07 803.24 432.2 801.49C432.22 801.48 441.69 800.41 447.1 827.59Z"
                  fill="black"
                />
                <path
                  id="Vector_95"
                  d="M423.46 800.58L425.98 834.15H429.18V797.72L423.46 800.58Z"
                  fill="#444053"
                />
                <path
                  id="Vector_96"
                  opacity="0.1"
                  d="M431.28 217.68C422.209 218.355 413.428 221.176 405.66 225.91C403.66 227.12 401.66 228.59 400.83 230.76C400.11 232.64 400.41 234.76 400.44 236.76C400.71 253.98 381.51 267.01 382.11 284.22C382.35 291.05 385.78 297.41 386.41 304.22C387.46 315.58 380.74 326.03 374.3 335.44C380.935 335.844 387.47 333.677 392.55 329.39C399.345 352.04 400.223 376.054 395.1 399.14H404.31C405.22 399.074 406.133 399.186 407 399.47C407.808 399.854 408.532 400.394 409.13 401.06C417.74 409.63 423.51 422.3 420.22 434L439.82 426.65C444.51 424.89 449.27 423.1 453.35 420.2C457.43 417.3 460.85 413.09 461.71 408.15C462.24 405.15 462.07 401.57 464.54 399.83C465.757 399.183 467.055 398.702 468.4 398.4C473.78 396.5 476.16 390.28 477.26 384.68C478.599 377.809 479.02 370.791 478.51 363.81C477.64 352.11 474.18 340.23 477.03 328.81C478.64 322.4 482.22 316.43 482.51 309.81C482.98 298.9 474.45 289.5 473.18 278.65C472.29 271.09 475.02 263.44 474.13 255.88C473.636 251.978 472.208 248.253 469.968 245.02C467.727 241.787 464.74 239.142 461.26 237.31C458.85 236.07 456.26 235.24 453.88 233.87C448.45 230.72 444.98 225.05 440.05 221.15C435.12 217.25 426.91 215.78 423.2 220.85L431.28 217.68Z"
                  fill="black"
                />
                <path
                  id="Vector_97"
                  d="M431.28 216.43C422.209 217.105 413.428 219.926 405.66 224.66C403.66 225.87 401.66 227.34 400.83 229.51C400.11 231.39 400.41 233.51 400.44 235.51C400.71 252.73 381.51 265.76 382.11 282.98C382.35 289.8 385.78 296.16 386.41 302.98C387.46 314.34 380.74 324.79 374.3 334.21C380.933 334.599 387.463 332.434 392.55 328.16C399.344 350.804 400.222 374.811 395.1 397.89H404.31C405.22 397.827 406.134 397.942 407 398.23C407.81 398.607 408.535 399.144 409.13 399.81C417.74 408.39 423.51 421.05 420.22 432.75L439.82 425.4C444.51 423.64 449.27 421.85 453.35 418.95C457.43 416.05 460.85 411.84 461.71 406.9C462.24 403.9 462.07 400.32 464.54 398.58C465.757 397.933 467.055 397.452 468.4 397.15C473.78 395.25 476.16 389.03 477.26 383.43C478.611 376.554 479.042 369.529 478.54 362.54C477.67 350.84 474.21 338.96 477.06 327.54C478.67 321.13 482.25 315.16 482.54 308.54C483.01 297.63 474.48 288.23 473.21 277.38C472.32 269.82 475.05 262.18 474.16 254.61C473.664 250.709 472.236 246.984 469.996 243.751C467.755 240.519 464.769 237.874 461.29 236.04C458.88 234.8 456.29 233.97 453.91 232.61C448.48 229.45 445.01 223.78 440.08 219.88C435.15 215.98 426.94 214.51 423.23 219.58L431.28 216.43Z"
                  fill="#925978"
                />
                <g id="Group" opacity="0.1">
                  <path
                    id="Vector_98"
                    opacity="0.1"
                    d="M400.42 231.35C400.38 231.735 400.36 232.123 400.36 232.51C400.39 232.13 400.41 231.74 400.42 231.35Z"
                    fill="black"
                  />
                  <path
                    id="Vector_99"
                    opacity="0.1"
                    d="M473.1 271.38C473.46 266.08 474.64 260.76 474.2 255.48C473.87 260.78 472.69 266.1 473.1 271.38Z"
                    fill="black"
                  />
                  <path
                    id="Vector_100"
                    opacity="0.1"
                    d="M386.41 297.73C385.83 291.53 382.94 285.73 382.26 279.54C382.124 280.675 382.074 281.818 382.11 282.96C382.35 289.71 385.7 296.02 386.38 302.74C386.545 301.075 386.555 299.397 386.41 297.73Z"
                    fill="black"
                  />
                  <path
                    id="Vector_101"
                    opacity="0.1"
                    d="M477.1 322.36C476.164 326.429 475.827 330.613 476.1 334.78C476.2 332.35 476.535 329.935 477.1 327.57C478.71 321.16 482.29 315.19 482.58 308.57C482.627 307.394 482.574 306.217 482.42 305.05C481.64 311 478.53 316.54 477.1 322.36Z"
                    fill="black"
                  />
                  <path
                    id="Vector_102"
                    opacity="0.1"
                    d="M392.55 322.9C388.379 326.395 383.217 328.494 377.79 328.9C376.65 330.68 375.47 332.42 374.3 334.14C380.933 334.529 387.463 332.364 392.55 328.09C396.203 340.246 398.162 352.848 398.37 365.54C398.667 351.11 396.703 336.723 392.55 322.9V322.9Z"
                    fill="black"
                  />
                  <path
                    id="Vector_103"
                    opacity="0.1"
                    d="M477.29 378.19C476.19 383.79 473.81 390.01 468.43 391.9C467.083 392.2 465.784 392.685 464.57 393.34C462.1 395.08 462.27 398.68 461.74 401.66C460.88 406.6 457.47 410.8 453.38 413.66C449.29 416.52 444.54 418.36 439.85 420.12L421.15 427.12C421.053 428.997 420.751 430.858 420.25 432.67L439.85 425.32C444.54 423.56 449.3 421.77 453.38 418.87C457.46 415.97 460.88 411.76 461.74 406.82C462.27 403.82 462.1 400.24 464.57 398.5C465.787 397.853 467.085 397.372 468.43 397.07C473.81 395.17 476.19 388.95 477.29 383.35C478.429 377.522 478.902 371.584 478.7 365.65C478.568 369.861 478.097 374.055 477.29 378.19V378.19Z"
                    fill="black"
                  />
                  <path
                    id="Vector_104"
                    opacity="0.1"
                    d="M409.16 394.54C408.565 393.871 407.84 393.33 407.03 392.95C406.163 392.666 405.25 392.551 404.34 392.61H396.17C395.85 394.35 395.51 396.09 395.17 397.82H404.38C405.29 397.757 406.204 397.872 407.07 398.16C407.88 398.537 408.605 399.074 409.2 399.74C415.5 406.02 420.27 414.48 421.09 423.16C422.1 412.78 416.72 402.1 409.16 394.54Z"
                    fill="black"
                  />
                </g>
                <path
                  id="Vector_105"
                  opacity="0.1"
                  d="M363.47 428.62C363.47 428.62 370.91 425.5 372.57 428.62C374.23 431.74 363.47 428.62 363.47 428.62Z"
                  fill="black"
                />
                <path
                  id="Vector_106"
                  opacity="0.1"
                  d="M366.1 433.97C366.1 433.97 373.54 430.85 375.21 433.97C376.88 437.09 366.1 433.97 366.1 433.97Z"
                  fill="black"
                />
              </g>
            </g>
            <g className={classes.cart}>
              <path
                id="Vector_107"
                d="M888.53 408.91C885.7 408.91 882.933 409.749 880.58 411.322C878.227 412.894 876.392 415.129 875.309 417.744C874.226 420.359 873.943 423.236 874.495 426.012C875.047 428.788 876.41 431.337 878.411 433.339C880.413 435.34 882.962 436.703 885.738 437.255C888.514 437.807 891.391 437.524 894.006 436.441C896.621 435.358 898.856 433.523 900.428 431.17C902.001 428.817 902.84 426.05 902.84 423.22C902.829 419.428 901.318 415.794 898.637 413.113C895.956 410.432 892.322 408.921 888.53 408.91V408.91ZM846.69 296.62V310.93H861L886.76 363.11L875.99 380.64C874.814 382.713 874.198 385.057 874.2 387.44C874.2 397.02 880.75 401.75 889.63 401.75H973.3V387.99H891.38C891.141 388.005 890.902 387.968 890.678 387.884C890.454 387.799 890.251 387.667 890.082 387.498C889.913 387.329 889.781 387.126 889.696 386.902C889.612 386.678 889.575 386.439 889.59 386.2C889.779 385.581 890.033 384.984 890.35 384.42L897.5 373.13H950.81C953.386 373.151 955.917 372.461 958.126 371.136C960.335 369.811 962.136 367.902 963.33 365.62L989.1 321.31C989.602 320.185 989.848 318.962 989.82 317.73C989.81 316.809 989.615 315.9 989.247 315.056C988.88 314.211 988.346 313.45 987.678 312.816C987.011 312.181 986.222 311.688 985.36 311.364C984.498 311.04 983.58 310.892 982.66 310.93H876.75L869.95 296.62H846.69ZM958.98 408.91C956.15 408.912 953.384 409.753 951.032 411.327C948.681 412.9 946.848 415.136 945.766 417.751C944.685 420.366 944.403 423.243 944.956 426.018C945.509 428.793 946.873 431.342 948.875 433.342C950.877 435.343 953.426 436.704 956.202 437.256C958.977 437.807 961.854 437.523 964.469 436.44C967.083 435.356 969.317 433.522 970.889 431.169C972.461 428.816 973.3 426.05 973.3 423.22C973.289 419.426 971.777 415.791 969.093 413.109C966.41 410.428 962.774 408.918 958.98 408.91V408.91Z"
                fill="#5CD89B"
              />
              <path
                id="Vector_108"
                d="M940.67 336.21H928.67V324.21H919.67V336.21H907.67V345.21H919.67V357.21H928.67V345.21H940.67V336.21Z"
                fill="white"
              />
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="612.42"
              y1="591.78"
              x2="612.42"
              y2="95.54"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#808080" stopOpacity="0.25" />
              <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
              <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
            </linearGradient>
            <clipPath id="clip0">
              <rect width="1116.21" height="857.08" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Grid>
    </Grid>
  );
};
